<template>
  <v-container v-if="pageLoading" class="bg-color-theme px-3">
    <v-skeleton-loader type="list-item-two-line, card"></v-skeleton-loader>
  </v-container>

  <v-container v-else class="bg-color-theme px-3">
    <v-card class="px-4">
      <v-expansion-panels flat v-model="expandPanelYear" multiple>
        <v-expansion-panel
          v-for="(panel, i) in getPanelData"
          :key="i"
          class="cost-panel"
        >
          <v-expansion-panel-header class="cost-panel-header">
            <span class="font-16px"> {{ panel.year + "年度" }} </span>
            <span class="text--secondary font-14px">{{
              panel.beginFiscalYear.format("YYYY/MM") +
                "~" +
                panel.endFiscalYear.format("YYYY/MM")
            }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels flat v-model="panel.mPanelExpand">
              <v-expansion-panel
                v-for="(item, i) in panel.months"
                :key="i"
                class="cost-panel"
              >
                <v-expansion-panel-header class="font-14px inventory-by-month">
                  {{ item.month + "月" }}
                  <v-icon
                    role="button"
                    class="ml-3 inventory-panel-head-icon"
                    small
                    @click="
                      $router.push({
                        name: 'InventoryManagementEdit',
                        query: {
                          year_month: getYearMonthFormatted(
                            item.year,
                            item.month
                          )
                        }
                      })
                    "
                    >$edit</v-icon
                  >
                </v-expansion-panel-header>
                <v-expansion-panel-content
                  class="info-bg rounded pt-3 font-12px"
                >
                  <div v-for="(inventory, j) in getInventories" :key="j">
                    <span
                      v-if="
                        inventory.year_month ==
                          getYearMonthFormatted(item.year, item.month)
                      "
                    >
                      <div
                        class="d-flex"
                        v-if="inventory.previous_this_month_food"
                      >
                        <div class="font-12px">前月の棚卸額</div>
                        <div class="ml-4 font-11px">
                          <div>
                            フード：{{
                              getPriceFormat(inventory.previous_this_month_food)
                            }}
                          </div>
                          <div>
                            ドリンク：{{
                              getPriceFormat(
                                inventory.previous_this_month_drink
                              )
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="d-flex mt-2">
                        <div class="font-12px">今月の棚卸額</div>
                        <div class="ml-4 font-11px">
                          <div>
                            フード：{{
                              getPriceFormat(inventory.this_month_food)
                            }}
                          </div>
                          <div>
                            ドリンク：{{
                              getPriceFormat(inventory.this_month_drink)
                            }}
                          </div>
                        </div>
                      </div>
                    </span>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  name: "InventoryManagementList",
  data() {
    return {
      pageLoading: true
    };
  },
  created() {
    let this_this = this;
    this.$root.$off("shopChanged");
    this.$root.$on("shopChanged", function() {
      this_this.getDataFromApi();
    });
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["getInventories"]),
    getShop() {
      return this.$store.getters.getFirstShopObject;
    },
    currentFiscalYear() {
      let now = dayjs();
      let month = now.month();
      let fiscalYearMonth = this.getShop.start_of_fiscal_year;
      let beginFiscalYear = null;
      let endFiscalYear = null;
      let currentFiscalYear = null;

      if (month < fiscalYearMonth) {
        beginFiscalYear = now
          .subtract(1, "year")
          .month(fiscalYearMonth)
          .subtract(1, "month");
      } else {
        beginFiscalYear = now.month(fiscalYearMonth).subtract(1, "month");
      }

      currentFiscalYear = beginFiscalYear.format("YYYY");
      endFiscalYear = beginFiscalYear.add(1, "year").subtract(1, "month");

      return {
        month: month,
        fiscalYearMonth: fiscalYearMonth,
        year: currentFiscalYear,
        beginFiscalYear: beginFiscalYear,
        endFiscalYear: endFiscalYear
      };
    },
    getPanelData() {
      let count = 0;
      let mPanelExpand = null;
      let currentMonth = dayjs().format("MM");
      let currentYear = dayjs().format("YYYY");

      let beginYear = parseInt(this.currentFiscalYear.year);
      let endYear = beginYear - 1;
      let endFiscalYear = "";
      let panels = [];
      // Generate month values (1-12)
      let months = [...Array(12).keys()].map(i => i + 1);
      // Shift month index so it starts from the fiscal year
      months = this.shiftLeft(months, this.getShop.start_of_fiscal_year);

      while (endYear <= beginYear) {
        let beginFiscalYear = this.currentFiscalYear.beginFiscalYear.year(
          endYear
        );

        if (
          this.currentFiscalYear.month < this.currentFiscalYear.fiscalYearMonth
        ) {
          endFiscalYear = this.currentFiscalYear.endFiscalYear.year(
            endYear + 1
          );
        } else {
          endFiscalYear = this.currentFiscalYear.endFiscalYear.year(endYear);
        }

        if (endFiscalYear.format("YYYY") == currentYear) {
          months.forEach(panelMonth => {
            count += 1;
            if (currentMonth == panelMonth) {
              mPanelExpand = count - 1;
            }
          });
        } else {
          mPanelExpand = null;
        }

        panels.push({
          beginFiscalYear: beginFiscalYear,
          endFiscalYear: endFiscalYear,
          year: endYear,
          mPanelExpand: mPanelExpand,
          // Advance trailing months to next year
          months: months.map(month => {
            return {
              month: month,
              year:
                month < this.getShop.start_of_fiscal_year
                  ? endYear + 1
                  : endYear
            };
          })
        });

        endYear++;
      }

      panels.sort((a, b) => (a.year < b.year ? 1 : -1));
      return panels;
    },
    expandPanelYear() {
      let array = [];
      let count = 0;
      this.getPanelData.forEach(panel => {
        count += 1;
        if (panel.year == this.currentFiscalYear.year) {
          array.push(count - 1);
        }
      });
      return array;
    }
  },
  watch: {
    getPanelData: {
      deep: true,
      handler: function(newVal) {
        for (var i = 0; i < newVal.length; i++) {
          for (var j = 0; j < newVal[i].months.length; j++) {
            var now = new Date();
            if (newVal[i].months[j] == now.getMonth() + 1) {
              this.panelExtend = [j];
            }
          }
        }
      }
    }
  },
  methods: {
    getDataFromApi() {
      this.shop_id = this.$store.getters.getFirstShopObject.id;
      let param = {
        id: this.$store.getters.getFirstShopObject.id //Shop Id
      };

      this.$store.dispatch("INVENTORY_GET_ALL", param).finally(() => {
        this.pageLoading = false;
      });

      this.$store.dispatch("SHOP_GET", param).finally(() => {
        this.pageLoading = false;
      });
    },
    getPriceFormat(amount) {
      return this.priceFormat(parseInt(amount));
    },
    shiftLeft(arr = [], n) {
      for (let index = 0; index < n - 1; index++) {
        arr.push(arr.shift());
      }
      return arr;
    },
    getYearMonthFormatted(year, month) {
      return `${year}/${String(month).padStart(2, "0")}`;
    }
  }
};
</script>
<style lang="scss" src="./style.scss"></style>
